import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultOpenGraphImage from "../assets/images/_og/find-your-weirdos-OG.png"

function SEO({
  description,
  lang = "en",
  meta = [],
  title,
  image,
  ogType = "website",
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
            siteUrl
          }
        }
      }
    `
  )

  const fullTitle =
    !title || title === site.siteMetadata.title
      ? site.siteMetadata.title
      : `${title} | ${site.siteMetadata.title}`
  const metaDescription = description || site.siteMetadata.description
  const ogImageUrl =
    site.siteMetadata.siteUrl + (image || defaultOpenGraphImage)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={fullTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: fullTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.social.twitter,
        },
        {
          name: `twitter:title`,
          content: fullTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImageUrl,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
      ].concat(meta)}
    />
  )
}

export default SEO
